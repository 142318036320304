import React from 'react'
import Box from '@mui/joy/Box'
import Card from '@mui/joy/Card'
import Divider from '@mui/joy/Divider'
import Drawer from '@mui/joy/Drawer'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'

import type { Post } from 'types/post'

import HeaderFeedDrawerItems from './header-feed-drawer-items'

interface HeaderFeedDrawerProps {
    isOpen: boolean
    setOpen: (isOpen: boolean) => void
}

export default function HeaderFeedDrawer({ isOpen,setOpen }: HeaderFeedDrawerProps) {

  const posts:Array<Post> = [{
    id: '1',
    title: 'What is Lorem Ipsum?',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
  }, {
    id: '2',
    title: 'What is Lorem Ipsum?',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
  }, {
    id: '3',
    title: 'What is Lorem Ipsum?',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
  }]

  return (
    <Drawer
      anchor={'right'}
      onClose={() => setOpen(false)}
      open={isOpen}
    >
      <Card
        color={'primary'}
        invertedColors={true}
        variant={'solid'}
        sx={{
          borderRadius: 0
        }}
      >
        <Stack p={2}>
          <Typography
            level={'h2'}>
            Feed
          </Typography>
        </Stack>
      </Card>
      <Box
        onClick={() => setOpen(false)}
        onKeyDown={() => setOpen(false)}
        role={'presentation'}
      >
        <HeaderFeedDrawerItems
          posts={posts}
          title={'NEW'}
        />
        <Divider />
        <HeaderFeedDrawerItems
          posts={posts}
          title={'TRENDING'}
        />
        <Divider />
        <HeaderFeedDrawerItems
          posts={posts}
          title={'EXPLORE'}
        />
      </Box>
    </Drawer>
  )
}
