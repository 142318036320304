import React from 'react'
import Button from '@mui/joy/Button'
import Stack from '@mui/joy/Stack'
import { SquarePen } from 'lucide-react'

import { FormTextField } from 'components/form/form-field'
import useMe from 'modules/state/me.hook'
import type { Post } from 'types/post'
import isEmpty from 'utils/isEmpty'

import { useCreateNewPost } from './start-new-post.hook'
import StartNewPostSnackbar from './start-new-post-snackbar.component'

export default function StartNewPost() {
  const me = useMe()
  const [newPost, setNewPost] = React.useState<Post | null>(null)

  const {
    register,
    handleSubmit,
    formState: { errors },
    loading
  } = useCreateNewPost(setNewPost)

  const isOpenSnackbar = !isEmpty(newPost)
  const clearNewPost = React.useCallback(() => {
    setNewPost(null)
  }, [newPost])

  if (!me.isLoggedIn) {
    return null
  }

  return (
    <Stack>
      <form onSubmit={handleSubmit}>
        <FormTextField
          placeholder={'Start creating new post with title . . .'}
          size={'lg'}
          variant={'soft'}
          {...register('title', { required: true })}
          startDecorator={<SquarePen />}
          endDecorator={
            <Button
              loading={loading}
              size={'lg'}
              type={'submit'}>
                Create
            </Button>
          }
        />
      </form>
      <StartNewPostSnackbar
        close={clearNewPost}
        newPost={newPost}
        open={isOpenSnackbar} />
    </Stack>
  )
}
