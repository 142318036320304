import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/joy/Button'
import Chip from '@mui/joy/Chip'
import Container from '@mui/joy/Container'
import Link from '@mui/joy/Link'
import Stack from '@mui/joy/Stack'
import { styled } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'

import Rest from 'modules/rest'

import ModeToggle from '../mode-toggle'

const StyledContainer = styled('div')(({ theme }) =>({
  backgroundColor: theme.palette.background.body
}))

interface SystemStatus {
  status: 'success' | 'danger'
  label: string
}

export default function Footer() {
  const [systemStatus, setSystemStatus] = React.useState<SystemStatus | null>(null)
  const { t, i18n } = useTranslation()
  const appName = process.env.REACT_APP_NAME

  const languages: Record<string, string> = {
    en: 'English',
    nl: 'Nederlands',
    de: 'Deutsch',
    sr: 'Srpski',
    'sr-Cyrl': 'Српски'
  }

  const date = new Date()

  React.useEffect(() => {
    Rest.systemPing.all().then((response) => {
      setSystemStatus({
        status: 'success',
        label: response?.system_status?.label
      })
    }).catch(() => {
      setSystemStatus({
        status: 'danger',
        label: 'down'
      })
    })
  }, [i18n.language])

  return (
    <StyledContainer>
      <Container maxWidth={'lg'}>
        <Stack
          alignItems={'center'}
          gap={1}
          py={2}>
          <Stack
            direction={'row'}
            gap={1}>
            <Link
              href={'/support/about'}>
              {t('About')}
            </Link>
            <Link
              href={'/support/help'}>
              {t('Help')}
            </Link>
            <Link
              href={'/support/privacy'}>
              {t('Privacy')}
            </Link>
            <Link
              href={'/support/terms'}>
              {t('Terms')}
            </Link>
            <Link
              href={'/support/rules'}>
              {t('Rules')}
            </Link>
          </Stack>

          <Stack
            direction={'row'}
            gap={1}>
            {Object.keys(languages).map((key: string) => (
              <Button
                key={`i18n-${key}`}
                disabled={key === i18n.language}
                onClick={() => i18n.changeLanguage(key)}
                size={'sm'}
                variant={'plain'}
              >
                {languages[key]}
              </Button>
            ))}
          </Stack>

          <ModeToggle />
          <Chip
            color={systemStatus?.status}
            size={'sm'}
            variant={'solid'}
          >
            {systemStatus?.label}
          </Chip>
          <Typography level={'body-sm'}>
            {t('Copyright © {{0}} {{1}}.', { 0: appName , 1: date.getFullYear() })}
          </Typography>
        </Stack>
      </Container>
    </StyledContainer>
  )
}
