import React from 'react'

export default function ProfilePasswordPage() {

  return (
    <>
        profile-password
    </>
  )
}
