import React from 'react'
import SupportPage from 'pages/support'
import SupportAboutPage from 'pages/support/support-about'
import SupportHelpPage from 'pages/support/support-help'
import SupportPrivacyPage from 'pages/support/support-privacy'
import SupportTermsPage from 'pages/support/support-terms'

import SupportRulesPage from '../pages/support/support-rules'

const supportRouter = {
  path: 'support',
  element: <SupportPage />,
  children: [
    {
      path: 'about',
      element: <SupportAboutPage />
    }, {
      path: 'help',
      element: <SupportHelpPage />
    }, {
      path: 'privacy',
      element: <SupportPrivacyPage />
    }, {
      path: 'terms',
      element: <SupportTermsPage />
    }, {
      path: 'rules',
      element: <SupportRulesPage />
    }
  ]
}

export default supportRouter
