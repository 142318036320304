import React from 'react'
import AspectRatio from '@mui/joy/AspectRatio'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemButton from '@mui/joy/ListItemButton'
import ListItemContent from '@mui/joy/ListItemContent'
import ListItemDecorator from '@mui/joy/ListItemDecorator'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import { ChevronRight, Image, Signpost } from 'lucide-react'

import type { Post } from 'types/post'

interface HeaderFeedDrawerItemProps {
    title: string
    posts: Array<Post>
}

export default function HeaderFeedDrawerItems({ title, posts }: HeaderFeedDrawerItemProps) {

  return (
    <>
      <List size={'lg'}>
        <ListItem>
          <ListItemButton>
            <ListItemDecorator><Signpost /></ListItemDecorator>
            <ListItemContent>{title}</ListItemContent>
            <ChevronRight />
          </ListItemButton>
        </ListItem>
      </List>

      <List>
        {posts.map((item) => (
          <ListItem key={item.id}>
            <ListItemButton>
              <Stack
                direction={'row'}
                gap={2}>
                <AspectRatio
                  ratio={'4/3'}
                  sx={{ minWidth: 100 }}>
                  <div>
                    <Image style={{ fontSize: '3rem', opacity: 0.2 }} />
                  </div>
                </AspectRatio>
                <Stack justifyContent={'center'}>
                  <Typography>
                    {item.title}
                  </Typography>
                  <Typography
                    level={'body-sm'}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '2',
                      WebkitBoxOrient: 'vertical'
                    }}>
                    {item.description}
                  </Typography>
                </Stack>
              </Stack>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  )
}
