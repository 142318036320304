import React from 'react'
import { FieldError } from 'react-hook-form/dist/types/errors'
import FormControl from '@mui/joy/FormControl'
import FormHelperText from '@mui/joy/FormHelperText'
import IconButton from '@mui/joy/IconButton'
import Input, { InputProps } from '@mui/joy/Input'
import LinearProgress from '@mui/joy/LinearProgress'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import { Eye, EyeOff, Key } from 'lucide-react'

interface FormPasswordFieldProps extends InputProps {
  field?: FieldError
  noMeter?: boolean
  watch?: (key: string) => string
}

const WEAK_PASSWORD = /^((?=(.*[a-z])+)|(?=(.*[A-Z])+)|(?=(.*[0-9])+)).{8,}$/
const STRONG_PASSWORD = /^(?=(.*[a-z])+)(?=(.*[A-Z])+)(?=(.*[0-9])+)(?=(.*[/!@#$%^&*()\-_+.])+).{8,}$/
const VERY_STRONG_PASSWORD = /^(?=(.*[a-z]){2,})(?=(.*[A-Z]){2,})(?=(.*[0-9]){2,})(?=(.*[!@#$%^&*()\-_+.]){2,}).{14,}$/

function FormPasswordField({ field, watch, noMeter = false, ...inputRest }: FormPasswordFieldProps, ref: React.ForwardedRef<HTMLInputElement>) {
  const [visible, setVisible] = React.useState(false)

  let passwordRating = 0
  let value = ''
  if (!noMeter && inputRest.name && watch) {
    value = watch(inputRest.name)
    if (value) {
      if (value.match(WEAK_PASSWORD)) {
        passwordRating = 33
      }
      if (value.match(STRONG_PASSWORD)) {
        passwordRating = 66
      }
      if (value.match(VERY_STRONG_PASSWORD)) {
        passwordRating = 100
      }
    }
  }

  const isRequired = field?.type === 'required'

  return (
    <FormControl
      error={isRequired}
      sx={{
        '--hue': Math.min(passwordRating * 1.2, 120)
      }}
    >
      <Input
        ref={ref}
        color={'primary'}
        startDecorator={<Key />}
        endDecorator={(
          <IconButton onClick={() => setVisible(!visible)}>
            {visible ? <Eye /> : <EyeOff />}
          </IconButton>
        )}
        {...inputRest}
        type={visible ? 'text' : 'password'}
      />

      {!noMeter && value && (
        <Stack px={0.4}>
          <LinearProgress
            size={'sm'}
            value={passwordRating}
            sx={{
              bgcolor: 'background.level3',
              color: 'hsl(var(--hue) 80% 40%)'
            }}
            determinate
          />
          <Typography
            level={'body-xs'}
            sx={{ alignSelf: 'flex-end', color: 'hsl(var(--hue) 80% 30%)' }}
          >
            {passwordRating < 33 && 'Very weak'}
            {passwordRating >= 33 && passwordRating < 66 && 'Weak'}
            {passwordRating >= 66 && passwordRating < 100 && 'Strong'}
            {passwordRating === 100 && 'Very strong'}
          </Typography>
        </Stack>
      )}
      {isRequired && (
        <FormHelperText>
          This field is required
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default React.forwardRef(FormPasswordField)
