import Methods from './methods'

const Rest = {
  systemPing: new Methods( '/api/system/ping/'),

  auth: new Methods( '/api/auth/'),

  register: new Methods( '/api/auth/register/'),
  activation: new Methods('/api/auth/activation'),
  requestAccount: new Methods('/api/auth/request-account'),

  resetPassword: new Methods('/api/auth/reset_password'),
  resetPasswordSet: new Methods('/api/auth/reset_password/set'),

  users: new Methods('/api/users/'),
  passwordChange: new Methods('/api/users/:id/password/'),

  posts: new Methods('/api/posts/'),

  tags: new Methods('/api/tags/'),
  images: new Methods('/api/images/'),

  errors: new Methods('/api/errors/'),

  ping: new Methods('/api/system/ping/')
}

export default Rest
