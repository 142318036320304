import React from 'react'
import { Snackbar } from '@mui/joy'
import LinearProgress from '@mui/joy/LinearProgress'
import Link from '@mui/joy/Link'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'

import type { Post } from 'types/post'

interface StartNewPostSnackbarProps {
    open: boolean
    close: () => void
    newPost: Post | null
}
export default function StartNewPostSnackbar({ open, close, newPost }: StartNewPostSnackbarProps) {

  const duration = 5000
  const [left, setLeft] = React.useState<undefined | number>()
  const timer = React.useRef<undefined | number>()

  React.useEffect(() => {
    if (open && duration !== undefined && duration > 0) {
      setLeft(duration)
      countdown()
    } else {
      window.clearInterval(timer.current)
    }
  }, [open, duration])

  function countdown() {
    timer.current = window.setInterval(() => {
      setLeft((prev) => (prev === undefined ? prev : Math.max(0, prev -10 )))
    }, 10 )
  }

  function handlePause() {
    window.clearInterval(timer.current)
  }

  function handleResume() {
    countdown()
  }

  return (

    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={duration}
      color={'success'}
      onBlur={handleResume}
      onClose={close}
      onFocus={handlePause}
      onMouseEnter={handlePause}
      onMouseLeave={handleResume}
      onUnmount={() => setLeft(undefined)}
      open={open}
      resumeHideDuration={left}
      variant={'outlined'}
    >
      <Stack
        width={'100%'}>
        <Typography
          color={'success'}
          level={'title-lg'}>
          New post created
        </Typography>
        <Typography
          color={'neutral'}
          level={'body-md'}
          mb={2}>
          Click here to continue working on post
          <Link href={`/profile/posts/${newPost?.id}`}>
           Continue
          </Link>
        </Typography>
        {left && left > 0
          ? (
            <LinearProgress
              color={'success'}
              value={(left * 100) / duration}
              variant={'solid'}
              determinate
            />
          )
          : ''}
      </Stack>
    </Snackbar>
  )
}
