import { styled } from '@mui/joy/styles'

const ContainerWrapper = styled('div')({
  position: 'relative',
  minHeight: '80vh',
  width: '100%'
})

const Container = styled('div')({
  width: 100,
  height: 100,
  overflow: 'auto',
  margin: 'auto',
  position: 'absolute',
  top: 0, left: 0, bottom: 0, right: 0
})

const Roller = styled('div')(({ theme }) => ({
  '@keyframes loading': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  },

  display: 'inline-block',
  position: 'relative',
  width: 80,
  height: 80,

  '& div': {
    animation: 'loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
    transformOrigin: '40px 40px',
    '&:after': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      width: 12,
      height: 12,
      borderRadius: '50%',
      background: theme.palette.primary.plainColor,
      margin: '-4px 0 0 -4px'
    },

    '&:nth-of-type(1)': {
      animationDelay: '-0.036s',
      '&:after': {
        top: 63,
        left: 63
      }
    },
    '&:nth-of-type(2)': {
      animationDelay: '-0.072s',
      '&:after': {
        top: 68,
        left: 58
      }
    },
    '&:nth-of-type(3)': {
      animationDelay: '-0.108s',
      '&:after': {
        top: 71,
        left: 48
      }
    },
    '&:nth-of-type(4)': {
      animationDelay: '-0.144s',
      '&:after': {
        top: 72,
        left: 40
      }
    },
    '&:nth-of-type(5)': {
      animationDelay: '-0.188s',
      '&:after': {
        top: 71,
        left: 32
      }
    },
    '&:nth-of-type(6)': {
      animationDelay: '-0.216s',
      '&:after': {
        top: 68,
        left: 24
      }
    },
    '&:nth-of-type(7)': {
      animationDelay: '-0.252s',
      '&:after': {
        top: 63,
        left: 17
      }
    },
    '&:nth-of-type(8)': {
      backgroundColor: 'green !important',
      animationDelay: '-288ms',
      '&:after': {
        top: 56,
        left: 12
      }
    }
  }
}))

function Loading() {

  return (
    <ContainerWrapper>
      <Container>
        <Roller>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
        </Roller>
      </Container>
    </ContainerWrapper>
  )
}

export default Loading
