import React from 'react'
import { FieldError } from 'react-hook-form/dist/types/errors'
import FormControl from '@mui/joy/FormControl'
import FormHelperText from '@mui/joy/FormHelperText'
import Option from '@mui/joy/Option'
import Select, { SelectProps } from '@mui/joy/Select'

import capitalize from 'utils/capitalize'
import omit from 'utils/omit'

import { FormFieldType } from './form-field.type'

// @ts-ignore
interface FormSelectFieldProps extends FormFieldType, SelectProps<string, string> {
  options?: Array<string>
  setValue: (name: string, value: unknown) => void
}

function FormSelectField({ field, options, setValue, ...selectRest }: FormSelectFieldProps, ref: React.ForwardedRef<HTMLSelectElement>) {
  const isRequired = (field as FieldError | undefined)?.type === 'required'

  function handleOnChange(event: Event, newValue: string) {
    event.preventDefault()
    if (selectRest.name){
      setValue(selectRest.name, newValue)
    }
  }

  return (
    <FormControl
      error={isRequired}
      sx={{ display: 'flex', gap: 3 }}
    >
      <Select
        {...omit(selectRest, ['watch', 'label'])}
        // @ts-ignore
        onChange={handleOnChange}
      >
        {options?.map((value) => (
          <Option
            key={value}
            label={capitalize(value) as string}
            value={value}
          >
            {capitalize(value)}
          </Option>
        ))}

      </Select>
      {isRequired && (
        <FormHelperText>
          This field is required
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default React.forwardRef(FormSelectField)
