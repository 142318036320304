import React from 'react'

import { RestContext } from './rest.context'

export default function RestProvider({ children }: React.PropsWithChildren) {
  const [store, setStore] = React.useState({})

  const update = React.useCallback((key: string, data: unknown) => {
    setStore({
      ...store,
      [key]: data
    })
  }, [store])

  return (
    <RestContext.Provider value={{ ...store, update }}>
      {children}
    </RestContext.Provider>
  )
}
