import React from 'react'
import { Outlet } from 'react-router-dom'
import Box from '@mui/joy/Box'
import Container from '@mui/joy/Container'

import Footer from 'components/footer'
import Header from 'components/header'
import Loading from 'components/loading'
import StartNewPost from 'components/start-new-post'
import i18n from 'modules/i18n'
import useMe from 'modules/state/me.hook'

export default function RootPage() {
  const [loading, setLoading] = React.useState(true)
  const me = useMe(false)

  React.useEffect(() => {
    Promise.all([
      i18n,
      me.invalidate()
    ]).finally(() => {
      setLoading(false)
    })

  }, [])

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 'calc(var(--Header-height, 0) + 20px)'
      }}>
      <Header />
      <Container maxWidth={'md'}>
        <StartNewPost />
        <Outlet />
      </Container>
      <Box flexGrow={1} />
      <Footer />
    </div>
  )
}
