import isArray from './isArray'
import isEmpty from './isEmpty'

export default function isObject(value: unknown) {
  if (isEmpty(value)) {
    return false
  }
  return typeof value === 'object' && !isArray(value)
}

