import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@mui/joy/Container'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'

export default function SupportAboutPage() {
  const { t } = useTranslation()
  const appNameShort = process.env.REACT_APP_NAME

  return (
    <Container maxWidth={'md'}>
      <Typography
        level={'h1'}
        mb={5}>
        {t('We provide small {{0}} posts for today', { 0: appNameShort })}
      </Typography>
      <Typography>
        {t('This is small community of regular people gathered together to share small, quick, {{0}} posts about any topic that can be informative.', { 0: appNameShort })}
      </Typography>
      <Stack
        alignItems={'center'}
        mb={5}>
        <Stack
          width={{
            xs: '100%',
            sm: '60%'
          }}>
          <img
            alt={'undraw_team_collaboration_re_ow29'}
            src={'assets/images/undraw_sharing_articles_re_jnkp.svg'} />
        </Stack>
      </Stack>
      <Typography>
        {t('Each {{0}} post can be a link to another resource where you can find more.', { 0: appNameShort })}
      </Typography>
      <Typography>
        {t('Each {{0}} post can be a place where you can seek a direction on how to search more about it.', { 0: appNameShort })}
      </Typography>
      <Typography
        level={'h2'}
        my={5}>
        {t('Check out our posts that are quick read')}
      </Typography>
      <Stack alignItems={'center'}>
        <Stack
          width={{
            xs: '100%',
            sm: '60%'
          }}>
          <img
            alt={'undraw_happy_news_re_tsbd'}
            src={'assets/images/undraw_happy_news_re_tsbd.svg'} />
        </Stack>
      </Stack>
      <Typography my={5}>
        {t('Get yourself interested in new stuff or find out more about things that you are already interested in')}
      </Typography>
      <Stack
        alignItems={'center'}>
        <Stack
          width={{
            xs: '100%',
            sm: '60%'
          }}>
          <img
            alt={'undraw_team_up_re_84ok'}
            src={'assets/images/undraw_team_up_re_84ok.svg'} />
        </Stack>
      </Stack>
    </Container>
  )
}
