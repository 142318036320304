import Box from '@mui/joy/Box'
import Stack from '@mui/joy/Stack'
import { styled, useColorScheme } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'

import apple_black from './assets/apple_black.png'
import apple_white from './assets/apple_white.png'
import google from './assets/google.svg'

const StyledBox = styled(Box)(({ theme }) =>({
  '&:hover': {
    cursor: 'pointer',
    opacity: 0.8
  }
}))

export default function SocialLogin() {
  const { mode } = useColorScheme()

  return (
    <Stack
      alignItems={'center'}>
      <Typography
        level={'body-md'}>
        Or continue with social media
      </Typography>
      <Stack
        direction={'row'}
        justifyContent={'space-around'}
        py={2}
        width={150}
      >
        <StyledBox>
          <img
            alt={'google-login'}
            src={google}
            width={'44px'}/>
        </StyledBox>
        <StyledBox>
          <img
            alt={'apple-login'}
            src={mode === 'light' ? apple_black : apple_white}
            width={'44px'}/>
        </StyledBox>
      </Stack>
    </Stack>
  )
}
