import cookie from 'modules/cookie'
import i18n from 'modules/i18n'
import each from 'utils/each'
import isEmpty from 'utils/isEmpty'
import isObject from 'utils/isObject'
import join from 'utils/join'
import replace from 'utils/replace'

class Methods {

  endpoint: string

  constructor(endpoint: string) {
    this.endpoint = endpoint
  }

  getHeaders = (body: object | undefined) => {
    const isFormData: boolean = body instanceof FormData

    const headers: Record<string, string> = {
      'Accept-Language': i18n.language,
      'Content-Disposition': '',
      'Authorization': ''
    }

    if (isFormData) {
      // TODO: Form Data file upload
      const bodyData = body as FormData
      const filenameList: string[] = []
      bodyData.forEach((field: FormDataEntryValue, key: string) => {
        if (field instanceof File) {
          filenameList.push(`name="${key}"; filename="${field.name}"`)
        }
      })
      headers['Content-Disposition'] = `attachment; ${join(filenameList, ' ')}`

    } else {
      headers.Accept = 'application/json'

      headers['Content-Type'] = 'application/json'
    }

    const token = cookie.get('token')
    if (token) {
      headers['Authorization'] = `Token ${token}`
    }

    return headers
  }

  handleFetch = async (url: string, body?: object | undefined, method = 'GET') => {

    const bodyData = body instanceof FormData ? body : body && JSON.stringify(body)
    const headers = this.getHeaders(body)

    const response = await fetch(
      url, {
        body: bodyData,
        method,
        headers
      }
    )
    const { status } = response
    const data = status !== 204 && (await response.json())
    if (status === 401) {
      cookie.remove('token')
      throw { errorMessage: i18n.t('Session expired. Please login again.') }
    }
    if (status >= 400 && status < 500) {
      if (data.message && data.code) {
        throw {
          message: join(data.message, ' '),
          code: join(data.code, ' ')
        }
      }
      if (data.non_field_errors) {
        throw { errorMessage: data.non_field_errors }
      }
      if (isObject(data)) {
        throw data
      }
    }
    return await data
  }

  generateUrl = (id?: string | null, params?: Record<string, unknown> | null, replacements?: Record<string, unknown>) => {
    if (!this.endpoint) {
      throw new Error('Missing endpoint')
    }

    let url = `${process.env.REACT_APP_API_URL}${this.endpoint}`
    if (id) {
      url += `${id}/`
    }

    if (!isEmpty(params)) {
      const paramsList: string[] = []
      each((params as Record<string, unknown>), (value: unknown, key: string) => {
        if (isEmpty(value)) {
          return
        }
        paramsList.push(`${key}=${value}`)
      })
      url += `?${join(paramsList, '&')}`
    }

    if (!isEmpty(replacements)) {
      each(replacements, (value: unknown, key: string) => {
        url = replace(url, key, value)
      })
    }

    return url
  }

  all = (params: Record<string, unknown> | null = null, replacements?: Record<string, unknown>) => {
    const url = this.generateUrl(null, params, replacements)
    return this.handleFetch(url)
  }

  get = (id: string, params?: Record<string, unknown>, replacements?: Record<string, unknown>) => {
    if (!id) {
      throw new Error('Missing id for Methods.get method')
    }
    const url = this.generateUrl(id, params, replacements)
    return this.handleFetch(url)
  }

  post = (data: object, replacements?: Record<string, unknown>) => {
    const url = this.generateUrl(null, null, replacements)
    return this.handleFetch(url, data, 'POST')
  }

  patch = (id: string, data: object, replacements?: Record<string, unknown>, params?: Record<string, unknown>) => {
    if (!id) {
      throw new Error('Missing id for Methods.patch method')
    }
    if (isEmpty(data)) {
      throw new Error('Missing data for Methods.patch method')
    }
    const url = this.generateUrl(id, params, replacements)
    return this.handleFetch(url, data, 'PATCH')
  }

  remove = (id:string, replacements?: Record<string, unknown>) => {
    if (!id && isEmpty(id)) {
      throw new Error('Missing id for Methods.remove method')
    }
    const url = this.generateUrl(id, null, replacements)
    return this.handleFetch(url, undefined, 'DELETE')
  }
}

export default Methods
