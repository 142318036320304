import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@mui/joy/Container'
import Link from '@mui/joy/Link'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import { Dot } from 'lucide-react'

export default function SupportHelpPage() {
  const { t } = useTranslation()

  return (
    <Container maxWidth={'md'}>

      <Typography
        level={'h1'}
        mb={5}>
        {t('Help')}
      </Typography>
      <Stack
        alignItems={'center'}
        direction={'row'}
        mb={2}>
        <Dot /> <Typography level={'h3'}>{t('How to create account')}</Typography>
      </Stack>
      <Stack ml={3}>
        <Typography >
          {t('Visit our page to create account.')}
        </Typography>
        <Typography >
          {t('Be aware that after account is created, verification email will be sent to your email.')}
        </Typography>
        <Typography >
          {t('To verify account navigate to link that you will find in email.')}
        </Typography>
        <Link
          href={'/auth/register'}
        >
          {t('Register')}
        </Link>
      </Stack>
      <Stack
        alignItems={'center'}
        direction={'row'}
        mb={2}
        mt={5}>
        <Dot /> <Typography level={'h3'}>{t('Contribute to our community')}</Typography>
      </Stack>
      <Stack ml={3}>
        <Typography>
          {t('After login into your account you can easily request to become author')}
        </Typography>

        <Link href={'/profile/author'}>{t('Become author')}</Link>
        <Typography>
          {t('Write us request letter explaining how do you want to contribute to our community.')}
        </Typography>
      </Stack>

      <Stack
        alignItems={'center'}
        direction={'row'}
        my={3}>
        <Dot /> <Typography>{t('If you have any other problems write us on {{0}}', { 0: process.env.REACT_APP_OFFICE_EMAIL })}</Typography>
      </Stack>
    </Container>
  )
}
