import React from 'react'
import { useForm } from 'react-hook-form'
import { Keys } from 'react-hook-form/dist/types/path/common'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'

import Rest from 'modules/rest'
import useMe from 'modules/state/me.hook'

type RegisterInputs = {
  email: string
  password: string
  confirm_password: string
  terms_accepted: boolean
}

type RegisterResponse = {
  registered: boolean
}

export function useRegister() {
  const [loading, setLoading] = React.useState(false)
  const [registerDone, setRegisterDone] = React.useState(false)
  const [captcha, setCaptcha] = React.useState(null)
  const { invalidate } = useMe(false)

  const {
    register: formRegister,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<RegisterInputs>()

  function register(data: RegisterInputs) {
    setLoading(true)
    return Rest.register.post({
      ...data,
      captcha
    }).then(async (response: RegisterResponse) => {
      await invalidate()
      setRegisterDone(response.registered)
    }).catch((error) => {
      // TODO: error message
      console.log(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  return {
    register: (name: Keys<RegisterInputs>, options?: RegisterOptions<RegisterInputs, Keys<RegisterInputs>>) => ({ ...formRegister(name, options), watch }),
    handleSubmit: handleSubmit(register),
    formState: { errors },
    loading,
    watch,
    setCaptcha,
    registerDone
  }
}
