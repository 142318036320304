import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@mui/joy/Container'
import Link from '@mui/joy/Link'
import Typography from '@mui/joy/Typography'

export default function SupportRulesPage() {
  const { t } = useTranslation()
  const appName = process.env.REACT_APP_NAME
  const officeMail = process.env.REACT_APP_OFFICE_EMAIL

  return (
    <Container maxWidth={'md'}>
      <Typography level={'h1'}>
        {t('Site rules')}
      </Typography>
      <Typography>
        {t('All participants of {{0}} are expected to abide by our Site Rules, both online and during in-person events that are hosted and/or associated with {{0}}.', { 0: appName })}
      </Typography>
      <Typography
        level={'h2'}
        mt={3}>
        {t('Our Pledge')}
      </Typography>
      <Typography>
        {t('We as members, contributors, and leaders pledge to make participation in our community a harassment-free experience for everyone, regardless of age, body size, visible or invisible disability, ethnicity, sex characteristics, gender identity and expression, level of experience, education, socio-economic status, nationality, personal appearance, race, caste, color, religion, or sexual identity and orientation.')}
      </Typography>
      <Typography>
        {t('We pledge to act and interact in ways that contribute to an open, welcoming, diverse, inclusive, and healthy community.')}
      </Typography>
      <Typography
        level={'h2'}
        mt={3}>
        {t('Our Standards')}
      </Typography>
      <Typography>
        {t('Examples of behavior that contributes to a positive environment for our community include:')}
      </Typography>
      <ul>
        <Typography component={'li'}>
          {t('Demonstrating empathy and kindness toward other people')}
        </Typography>
        <Typography component={'li'}>
          {t('Being respectful of differing opinions, viewpoints, and experiences')}
        </Typography>
        <Typography component={'li'}>
          {t('Giving and gracefully accepting constructive feedback')}
        </Typography>
        <Typography component={'li'}>
          {t('Accepting responsibility and apologizing to those affected by our mistakes, and learning from the experience')}
        </Typography>
        <Typography component={'li'}>
          {t('Focusing on what is best not just for us as individuals, but for the overall community')}
        </Typography>
      </ul>
      <Typography>
        {t('Examples of unacceptable behavior include:')}
      </Typography>
      <ul>
        <Typography component={'li'}>
          {t('The use of sexualized language or imagery, and sexual attention or advances of any kind')}
        </Typography>
        <Typography component={'li'}>
          {t('Trolling, insulting or derogatory comments, and personal or political attacks')}
        </Typography>
        <Typography component={'li'}>
          {t('Public or private harassment')}
        </Typography>
        <Typography component={'li'}>
          {t('Publishing others’ private information, such as a physical or email address, without their explicit permission')}
        </Typography>
        <Typography component={'li'}>
          {t('Other conduct which could reasonably be considered inappropriate in a professional setting')}
        </Typography>
      </ul>
      <Typography
        level={'h2'}
        mt={3}>
        {t('Enforcement Responsibilities')}
      </Typography>
      <Typography>
        {t('Community leaders are responsible for clarifying and enforcing our standards of acceptable behavior and will take appropriate and fair corrective action in response to any behavior that they deem inappropriate, threatening, offensive, or harmful.')}
      </Typography>
      <Typography>
        {t('Community leaders have the right and responsibility to remove, edit, or reject')}
      </Typography>
      <Typography>
        {t('Community leaders have the right and responsibility to remove, edit, or reject comments, posts, and other contributions that are not aligned to this Code of Conduct, and will communicate reasons for moderation decisions when appropriate.')}
      </Typography>
      <Typography
        level={'h2'}
        mt={3}>
        {t('Scope')}
      </Typography>
      <Typography>
        {t('This Code of Conduct applies within all community spaces, and also applies when an individual is officially representing the community in public spaces.')}
      </Typography>
      <Typography>
        {t('Examples of representing our community include using an official e-mail address, posting via an official social media account, or acting as an appointed representative at an online or offline event.')}
      </Typography>
      <Typography
        level={'h2'}
        mt={3}>
        {t('Enforcement')}
      </Typography>
      <Typography>
        {t('Instances of abusive, harassing, or otherwise unacceptable behavior may be reported to the community leaders responsible for enforcement at {{1}}.',{ 1: officeMail } )}
      </Typography>
      <Typography>
        {t('All complaints will be reviewed and investigated promptly and fairly.')}
      </Typography>
      <Typography>
        {t('All community leaders are obligated to respect the privacy and security of the reporter of any incident.')}
      </Typography>
      <Typography
        level={'h2'}
        mt={3}>
        {t('Enforcement Guidelines')}
      </Typography>
      <Typography>
        {t('Community leaders will follow these Community Impact Guidelines in determining the consequences for any action they deem in violation of this Code of Conduct:')}
      </Typography>
      <Typography
        level={'h3'}
        mt={2}>
        {t('1. Correction')}
      </Typography>
      <Typography>
        <strong>{t('Community Impact:')}</strong>&nbsp;
        {t('Use of inappropriate language or other behavior deemed unprofessional or unwelcome in the community.')}
      </Typography>
      <Typography>
        <strong>{t('Consequence:')}</strong>&nbsp;
        {t('A private, written warning from community leaders, providing clarity around the nature of the violation and an explanation of why the behavior was inappropriate. A public apology may be requested.')}
      </Typography>
      <Typography
        level={'h3'}
        mt={2}>
        {t('2. Warning')}
      </Typography>
      <Typography>
        <strong>{t('Community Impact:')}</strong>&nbsp;
        {t('A violation through a single incident or series of actions.')}
      </Typography>
      <Typography>
        <strong>{t('Consequence:')}</strong>&nbsp;
        {t('A warning with consequences for continued behavior. No interaction with the people involved, including unsolicited interaction with those enforcing the Code of Conduct, for a specified period of time. This includes avoiding interactions in community spaces as well as external channels like social media. Violating these terms may lead to a temporary or permanent ban.')}
      </Typography>
      <Typography
        level={'h3'}
        mt={2}>
        {t('3. Temporary Ban')}
      </Typography>
      <Typography>
        <strong>{t('Community Impact:')}</strong>&nbsp;
        {t('A serious violation of community standards, including sustained inappropriate behavior.')}
      </Typography>
      <Typography>
        <strong>{t('Consequence:')}</strong>&nbsp;
        {t('A temporary ban from any sort of interaction or public communication with the community for a specified period of time. No public or private interaction with the people involved, including unsolicited interaction with those enforcing the Code of Conduct, is allowed during this period.')}
      </Typography>
      <Typography fontWeight={'bold'}>
        {t('Violating these terms may lead to a permanent ban.')}
      </Typography>

      <Typography
        level={'h3'}
        mt={2}>
        {t('4. Permanent Ban')}
      </Typography>
      <Typography>
        <strong>{t('Community Impact:')}</strong>&nbsp;
        {t('Demonstrating a pattern of violation of community standards, including sustained inappropriate behavior, harassment of an individual, or aggression toward or disparagement of classes of individuals.')}
      </Typography>
      <Typography>
        <strong>{t('Consequence:')}</strong>&nbsp;
        {t('A permanent ban from any sort of public interaction within the community.')}
      </Typography>
      <Typography
        level={'h2'}
        mt={3}>
        {t('Attribution')}
      </Typography>
      <Typography>
        {t('This Code of Conduct is adapted from the Contributor Covenant, version 2.1, available at:')}
      </Typography>
      <Link href={'https://www.contributor-covenant.org/version/2/1/code_of_conduct.html'}>
                    https://www.contributor-covenant.org/version/2/1/code_of_conduct.html
      </Link>
      <Typography>
        {t('Community Impact Guidelines were inspired by Mozilla’s code of conduct enforcement ladder.')}
      </Typography>
      <Typography>
        {t('For answers to common questions about this code of conduct, see the FAQ at')}
      </Typography>
      <Link href={'https://www.contributor-covenant.org/faq'}>
                    https://www.contributor-covenant.org/faq
      </Link>
      <Typography>
        {t('Translations are available at')}
      </Typography>
      <Link href={'https://www.contributor-covenant.org/translations'}>
                    https://www.contributor-covenant.org/translations
      </Link>

    </Container>
  )
}
