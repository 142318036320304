// import ReCAPTCHA from 'react-google-recaptcha'
import Button from '@mui/joy/Button'
import Divider from '@mui/joy/Divider'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import { ChevronDownCircle } from 'lucide-react'

import { FormCheckboxField, FormPasswordField,FormTextField } from 'components/form/form-field'

import SocialLogin from '../components/social-login'

import { useRegister } from './register.hook'

export default function RegisterPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    loading,
    setCaptcha,
    registerDone
  } = useRegister()

  if (registerDone) {
    return (
      <>
        Register done see email
      </>
    )
  }

  return (
    <>
      <Stack
        textAlign={'center'}>
        <Typography
          fontWeight={'md'}
          level={'h3'}>
          Create an acount
        </Typography>
        <Typography
          level={'body-sm'}>
          Don't worry, it's super fast.
        </Typography>
      </Stack>
      <form
        onSubmit={handleSubmit}
        noValidate>
        <Stack
          py={1}>
          <FormTextField
            field={errors.email}
            placeholder={'Email'}
            type={'email'}
            {...register('email', { required: true })}
          />
        </Stack>
        <Stack
          py={1}
        >
          <FormPasswordField
            field={errors.password}
            placeholder={'Password'}
            {...register('password', { required: true })}
          />
        </Stack>
        <Stack
          py={1}
        >
          <FormPasswordField
            field={errors.confirm_password}
            placeholder={'Confirm Password'}
            noMeter
            {...register('confirm_password', { required: true })}
          />
        </Stack>
        {/*{process.env.REACT_APP_RECAPTCHA_SITE_KEY && (*/}
        {/*  <ReCAPTCHA*/}
        {/*    onChange={setCaptcha}*/}
        {/*    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}*/}
        {/*  />*/}
        {/*)}*/}
        <Stack
          py={1}
        >
          <FormCheckboxField
            field={errors.terms_accepted}
            label={'I agree to Terms and Privacy'}
            {...register('terms_accepted', { required: true })}
          />
        </Stack>
        <Stack
          pt={4}>
          <Button
            loading={loading}
            type={'submit'}>
            Register
          </Button>
        </Stack>
      </form>
      <Divider inset={'none'}>
        <ChevronDownCircle
          size={'52px'}
          strokeWidth={'0.5px'}/>
      </Divider>
      <SocialLogin />
    </>
  )
}
