import React from 'react'

export default function LandingPage() {
  // TODO: load posts
  return (
    <>
      posts
    </>
  )
}
