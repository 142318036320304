import React from 'react'
import { Navigate } from 'react-router-dom'
import LandingPage from 'pages/landing'

import authRouter from './root-auth.router'
import profileRouter from './root-profile.router'
import supportRouter from './root-support.router'

const rootRouter = [
  authRouter,
  profileRouter,
  supportRouter,
  {
    path: '',
    element: <LandingPage />
  },
  {
    path: '*',
    element: <Navigate
      to={'/'}
      replace />
  }
]

export default rootRouter
