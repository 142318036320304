import React from 'react'
import Box from '@mui/joy/Box'
import Card from '@mui/joy/Card'
import Divider from '@mui/joy/Divider'
import Drawer from '@mui/joy/Drawer'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemButton from '@mui/joy/ListItemButton'
import ListItemContent from '@mui/joy/ListItemContent'
import ListItemDecorator from '@mui/joy/ListItemDecorator'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import { ChevronRight, KeyRound, Settings, User } from 'lucide-react'

import useMe from 'modules/state/me.hook'

interface HeaderProfileDrawerProps {
    isOpen: boolean
    setOpen: (isOpen: boolean) => void
}

export default function HeaderProfileDrawer({ isOpen,setOpen }: HeaderProfileDrawerProps) {
  const me = useMe(false)

  return (
    <Drawer
      anchor={'right'}
      onClose={() => setOpen(false)}
      open={isOpen}
      size={'sm'}
    >

      <Card
        color={'primary'}
        invertedColors={true}
        variant={'solid'}
        sx={{
          borderRadius: 0
        }}
      >
        <Stack p={2}>
          <Typography
            level={'title-lg'}>
            {me.name}
          </Typography>
          <Typography
            level={'body-lg'}>
            {me.email}
          </Typography>
        </Stack>
      </Card>
      <Box
        onClick={() => setOpen(false)}
        onKeyDown={() => setOpen(false)}
        role={'presentation'}
      >
        <List size={'md'}>
          <ListItem>
            <ListItemButton>
              <ListItemDecorator><User /></ListItemDecorator>
              <ListItemContent>Profile</ListItemContent>
              <ChevronRight />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton>
              <ListItemDecorator><KeyRound /></ListItemDecorator>
              <ListItemContent>Password Update</ListItemContent>
              <ChevronRight />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton>
              <ListItemDecorator><Settings /></ListItemDecorator>
              <ListItemContent>Settings</ListItemContent>
              <ChevronRight />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  )
}
