import isObject from './isObject'

export default function assign(target: unknown, ...rest: unknown[]) {

  if (!isObject(target)) {
    target = {}
  }

  rest = rest.filter((item) => isObject(item))

  for (let i = 0; i < rest.length; i++) {
    const source = rest[i] as Record<string, unknown>
    for (const key in source) {
      (target as Record<string, unknown>)[key] = source[key]
    }
  }
  return target
}
