import React from 'react'
import { useForm } from 'react-hook-form'

import Rest from 'modules/rest'

type RequestAccountInputs = {
  email: string
}

type RequestAccountResponse = {
  reset_sent: boolean
}

export function useForgotPassword() {
  const [loading, setLoading] = React.useState(false)
  const [resetPassword, setResetPassword] = React.useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<RequestAccountInputs>()

  function requestAccount(data: RequestAccountInputs) {
    setLoading(true)
    Rest.requestAccount.post(data)
      .then(async (response: RequestAccountResponse) => {
      // TODO: Display success message with some info and that mail will be send with small instruction
        console.log(response)
        setResetPassword(response.reset_sent)
      }).catch((error) => {
      // TODO: error message
        console.log(error)
      }).finally(() => {
        setLoading(false)
      })
  }

  return {
    register: register,
    handleSubmit: handleSubmit(requestAccount),
    formState: { errors },
    loading,
    resetPassword
  }
}
