import Button from '@mui/joy/Button'
import Stack from '@mui/joy/Stack'

import { FormTextField } from 'components/form/form-field'

import { useForgotPassword } from './forgot-password.hook'

export default function ForgotPasswordPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    loading,
    resetPassword
  } = useForgotPassword()

  if (resetPassword) {
    return (
      <>
        Reset password sent, check email
      </>
    )
  }

  return (
    <form
      onSubmit={handleSubmit}
      noValidate>
      <Stack
        py={2}>
        <FormTextField
          field={errors.email}
          placeholder={'Email'}
          type={'email'}
          {...register('email', { required: true })}
        />
      </Stack>
      <Stack
        py={2}>
        <Button
          loading={loading}
          type={'submit'}>
        Request password reset
        </Button>
      </Stack>
    </form>
  )
}
