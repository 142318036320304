import React from 'react'
import { FieldError } from 'react-hook-form/dist/types/errors'
import FormControl from '@mui/joy/FormControl'
import FormHelperText from '@mui/joy/FormHelperText'
import FormLabel from '@mui/joy/FormLabel'
import Input, { InputProps } from '@mui/joy/Input'

import omit from 'utils/omit'

import { FormFieldType } from './form-field.type'

interface FormTextFieldProps extends FormFieldType, InputProps {}

function FormTextField({
  field, help, label,startDecorator, endDecorator, ...inputRest
}: FormTextFieldProps, ref: React.ForwardedRef<HTMLInputElement>) {
  const isRequired = (field as FieldError | undefined)?.type === 'required'

  return (
    <FormControl
      error={isRequired}>
      {label && (
        <FormLabel required={isRequired}>
          {label}
        </FormLabel>
      )}
      <Input
        ref={ref}
        color={'primary'}
        endDecorator={endDecorator}
        startDecorator={startDecorator}
        {...omit(inputRest, ['watch'])}
      />
      {isRequired && (
        <FormHelperText>
          This field is required
        </FormHelperText>
      )}
      {help && (<FormHelperText>{help}</FormHelperText>)}
    </FormControl>
  )
}

export default React.forwardRef(FormTextField)
