import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Card from '@mui/joy/Card'
import Stack from '@mui/joy/Stack'

import useMe from 'modules/state/me.hook'

export default function AuthPage() {

  const location = useLocation()
  const navigate = useNavigate()

  const me = useMe()

  const { pathname } = location
  useEffect(() => {
    if (me.isLoggedIn) {
      // Handle when user is logged in but is on some auth page
      navigate('/', { replace: true })
    }
  }, [pathname, me.isLoggedIn])

  return (
    <Stack
      direction={'row'}
      justifyContent={'center'}
      py={{
        xs: 5,
        md: 15
      }}>
      <Card
        variant={'outlined'}
        sx={{
          width: 500,
          display: 'flex',
          gap: 2,
          px: 6
        }}>
        <Outlet />
      </Card>
    </Stack>
  )
}
