import React from 'react'

export default function ProfileSettingsPage() {

  return (
    <>
      ProfileSettingsPage
    </>
  )
}
