import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Container from '@mui/joy/Container'
import GlobalStyles from '@mui/joy/GlobalStyles'
import IconButton from '@mui/joy/IconButton'
import Link from '@mui/joy/Link'
import Stack from '@mui/joy/Stack'
import { Menu, Newspaper, Power } from 'lucide-react'

import Logo from 'components/logo'
import ModeToggle from 'components/mode-toggle'
import useMe from 'modules/state/me.hook'

import HeaderFeedDrawer from './header-feed-drawer'
import HeaderProfileDrawer from './header-profile-drawer'

export default function Header() {
  const { t } = useTranslation()
  const me = useMe()

  const [isFeedDrawerOpen, setFeedDrawerOpen] = React.useState(false)
  const [isProfileDrawerOpen, setProfileDrawerOpen] = React.useState(false)

  const logout = React.useCallback(() => {
    me.logout()
  }, [me.isLoggedIn])

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          zIndex: 1299,
          backgroundColor: 'background.body',
          width: '100%'
        }}>
        <Container maxWidth={'lg'}>
          <GlobalStyles
            styles={{
              ':root': {
                '--Header-height': '52px'
              }
            }}
          />
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            py={1}>
            <Stack
              alignItems={'center'}
              direction={'row'}
              gap={1}
              justifyContent={'center'}
            >
              <Link href={'/'}>
                <Logo />
              </Link>
            </Stack>

            <Stack
              alignItems={'center'}
              direction={'row'}
              gap={1}>

              {me.isLoggedIn && (
                <Stack
                  alignItems={'center'}
                  direction={'row'}
                  gap={2}>
                  <IconButton
                    onClick={() => setFeedDrawerOpen(true)}>
                    <Newspaper />
                  </IconButton>
                  <Button
                    onClick={() => setProfileDrawerOpen(true)}
                    variant={'plain'}>
                    {me.email}
                  </Button>
                  <IconButton
                    onClick={logout}>
                    <Power />
                  </IconButton>
                </Stack>
              )}

              {!me.isLoggedIn && (
                <>
                  <Button
                    component={Link}
                    href={'/auth'}
                    variant={'plain'}>
                    {t('Login')}
                  </Button>
                  <Button
                    component={Link}
                    href={'/auth/register'}
                    variant={'outlined'}>
                    {t('Register')}
                  </Button>
                </>
              )}
            </Stack>
          </Stack>
        </Container>
        {me.isLoggedIn && (<>
          <HeaderFeedDrawer
            isOpen={isFeedDrawerOpen}
            setOpen={setFeedDrawerOpen} />
          <HeaderProfileDrawer
            isOpen={isProfileDrawerOpen}
            setOpen={setProfileDrawerOpen} />
        </>)}

      </Box>
    </>
  )
}
