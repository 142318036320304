import React from 'react'
import { FieldError } from 'react-hook-form/dist/types/errors'
import Checkbox, { CheckboxProps } from '@mui/joy/Checkbox'
import FormControl from '@mui/joy/FormControl'
import FormHelperText from '@mui/joy/FormHelperText'

import omit from 'utils/omit'

import { FormFieldType } from './form-field.type'

interface FormCheckboxFieldProps extends FormFieldType, CheckboxProps {

}

function FormCheckboxField({ field, help, ...checkboxRest }: FormCheckboxFieldProps, ref: React.ForwardedRef<HTMLInputElement>) {
  const isRequired = (field as FieldError | undefined)?.type === 'required'

  return (
    <FormControl
      error={isRequired}
      sx={{ display: 'flex', gap: 3 }}
    >
      <Checkbox
        ref={ref}
        color={'primary'}
        variant={'outlined'}
        {...omit(checkboxRest, ['watch'])}
        size={'lg'}
      />
      {isRequired && (
        <FormHelperText>
          This field is required
        </FormHelperText>
      )}
      {help && (<FormHelperText>{help}</FormHelperText>)}
    </FormControl>
  )
}

export default React.forwardRef(FormCheckboxField)
