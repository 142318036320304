import isArray from './isArray'
import isEmpty from './isEmpty'
import isObject from './isObject'

type Iterate = Array<unknown> | Record<string, unknown> | undefined

type ArrayCallback = (value: unknown, index: number, array: unknown[]) => unknown
type ObjectCallback = (value: unknown, key: string, index: number ) => unknown

type IterateCallback = ArrayCallback | ObjectCallback

export default function each(iterate: Iterate = undefined, callback: IterateCallback) {
  if (!isEmpty(iterate)) {
    if (isArray(iterate)) {
      return (iterate as Array<unknown>).map(callback as ArrayCallback)
    }
    if (isObject(iterate)) {
      const keys = Object.keys(iterate as Record<string, unknown>)
      return keys.map((key, index) => {
        return (callback as ObjectCallback)((iterate as Record<string, unknown>)[key], key, index)
      })
    }
  }
}
