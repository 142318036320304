import React from 'react'
import { useForm } from 'react-hook-form'

import Rest from 'modules/rest'
import type { Post } from 'types/post'

type CreateNewPostInputs = {
  title: string
}

export function useCreateNewPost(setNewPost: (post: Post) => void) {
  const [loading, setLoading] = React.useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<CreateNewPostInputs>()

  function createNewPost(data: CreateNewPostInputs) {
    setLoading(true)
    Rest.posts.post(data).then(async (response: Post) => {
      console.log(response)
      setNewPost(response)
    }).catch((error) => {
      // TODO: error message
      console.log(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  return {
    register,
    handleSubmit: handleSubmit(createNewPost),
    formState: { errors },
    loading
  }
}
