import React from 'react'
import { useTheme } from '@mui/joy'

export default function Logo() {
  const theme = useTheme()
  const fillColor = theme.palette.text.primary
  return (
    <svg
      fill={fillColor}
      height={48}
      viewBox={'0 0 453 110'}
      width={150}
    >
      <symbol id={'a'}>
        <path d={'M.09.01a.1.1 0 0 0-.04.02.12.12 0 0 0-.02.03.29.29 0 0 0-.03.11 1.04 1.04 0 0 0 0 .06v.24C.01.5.01.53.01.55c.01.02.01.04.02.06.01.02.02.04.03.05a.11.11 0 0 0 .03.01.28.28 0 0 1 .02.01H.17V.67L.16.65H.12C.11.65.1.65.08.63A.12.12 0 0 1 .06.59.57.57 0 0 1 .04.43V.32L.03.22C.03.19.04.16.04.14A.15.15 0 0 1 .07.07C.08.04.11.03.14.03c.01 0 .02.01.03.01.01 0 .01 0 .01-.01 0 0 0-.01-.01-.02A.09.09 0 0 0 .14 0H.11L.09.01z'} />
      </symbol>
      <symbol id={'b'}>
        <path d={'M.23 0H.21v.02L.19.11v.04a.23.23 0 0 1-.05.01H.1a.32.32 0 0 1-.03 0H.06V.14A.72.72 0 0 0 .03.02C.03.01.03 0 .02 0a.02.02 0 0 0-.01.01C0 .01 0 .02 0 .02v.01c.01.04.02.09.03.14l.01.06.02.08.01.06.01.09a1.37 1.37 0 0 1 .03.19.1.1 0 0 0 .01.03h.01a.02.02 0 0 1 0-.01c.01 0 .02.01.02.01A.02.02 0 0 0 .16.67C.17.67.17.67.17.66A.99.99 0 0 1 .18.5L.24.02V.01A.02.02 0 0 0 .23 0M.18.18C.18.28.16.42.15.6L.09.34A1.05 1.05 0 0 1 .07.19S.08.19.11.18a.65.65 0 0 1 .06 0h.01z'} />
      </symbol>
      <symbol id={'c'}>
        <path d={'M.39 0C.38 0 .37.02.37.06V.35L.36.39a.28.28 0 0 0 0 .05V.61C.35.6.31.46.24.19V.17A.21.21 0 0 0 .23.13.04.04 0 0 0 .22.11a.01.01 0 0 0-.01 0C.2.11.19.11.19.12.18.12.18.13.18.14A3.55 3.55 0 0 1 .1.5a.47.47 0 0 1-.04.11C.06.59.06.54.05.45A6.31 6.31 0 0 1 .04.09V.05C.03.04.03.03.02.03L.01.04C0 .04 0 .04 0 .05c0 .06.01.16.02.31a4.84 4.84 0 0 1 .01.33.02.02 0 0 0 0 .01c0 .01.01.01.02.01S.07.71.07.7A.17.17 0 0 0 .08.67V.66A.42.42 0 0 1 .11.58.73.73 0 0 0 .15.46C.16.42.16.39.17.38L.18.35A.28.28 0 0 0 .21.19C.22.2.24.27.27.4a3.44 3.44 0 0 1 .07.29C.35.7.36.71.37.71A.03.03 0 0 0 .38.7C.39.7.39.7.39.69.39.41.4.27.41.25v-.1A.49.49 0 0 1 .42.03.04.04 0 0 0 .41.01C.41 0 .4 0 .39 0M.36.61z'} />
      </symbol>
      <symbol id={'d'}>
        <path d={'M.12 0C.04 0 0 .12 0 .37V.4c0 .19.02.28.07.28a.05.05 0 0 0 .02 0A.28.28 0 0 1 .15.66C.16.66.17.65.19.63a.28.28 0 0 0 .03-.1.66.66 0 0 0 .01-.12V.29a.96.96 0 0 0 0-.1.54.54 0 0 0-.02-.1.19.19 0 0 0-.03-.05.1.1 0 0 0-.02-.03A.06.06 0 0 0 .12 0M.11.03c.03 0 .05.02.06.05A.45.45 0 0 1 .19.2C.2.24.2.27.2.29.2.32.2.36.19.41a2.51 2.51 0 0 0 0 .12L.18.54a.12.12 0 0 1-.02.08.07.07 0 0 1-.06.03C.07.65.05.64.05.61A.76.76 0 0 1 .04.56a.55.55 0 0 0 0-.1.56.56 0 0 0-.01-.04V.29A.84.84 0 0 1 .06.11.17.17 0 0 1 .08.05C.09.04.1.03.11.03z'} />
      </symbol>
      <symbol id={'e'}>
        <path d={'M.03.01A.04.04 0 0 0 .01 0S0 0 0 .01s0 .01.01.02a.11.11 0 0 1 .04.04.4.4 0 0 1 .02.07c.01.05.02.11.02.17v.08a6.13 6.13 0 0 0-.01.24H.07C.06.63.05.64.05.64s.01.02.02.02a.08.08 0 0 0 .03 0h.08l.03.01C.23.67.24.66.25.66.26.66.26.65.26.63V.62a.5.5 0 0 1 0-.07V.48a3 3 0 0 0 0-.09L.25.3V.27A3.81 3.81 0 0 1 .27.03C.27.02.26.02.26.01H.25a.02.02 0 0 0-.02 0v.52A.95.95 0 0 1 .22.6a.1.1 0 0 1 0 .03H.11V.55L.12.49V.28a.69.69 0 0 0-.03-.2.13.13 0 0 0-.06-.07z'} />
      </symbol>
      <g className={'layer'}>
        <title>Layer 1</title>
        <use
          transform={'matrix(150 0 0 -150 171.45 226.8)'}
          x={-1.13}
          xlinkHref={'#a'}
          y={0.82}
        />
        <use
          transform={'matrix(150 0 0 -150 210.9 225.75)'}
          x={-1.13}
          xlinkHref={'#b'}
          y={0.82}
        />
        <use
          transform={'matrix(150 0 0 -150 262.95 232.2)'}
          x={-1.13}
          xlinkHref={'#c'}
          y={0.82}
        />
        <use
          transform={'matrix(150 0 0 -150 345.9 227.25)'}
          x={-1.13}
          xlinkHref={'#d'}
          y={0.82}
        />
        <use
          transform={'matrix(150 0 0 -150 399.9 232.2)'}
          x={-1.13}
          xlinkHref={'#c'}
          y={0.82}
        />
        <use
          transform={'matrix(150 0 0 -150 479.85 225.75)'}
          x={-1.13}
          xlinkHref={'#b'}
          y={0.82}
        />
        <use
          transform={'matrix(150 0 0 -150 526.95 224.55)'}
          x={-1.13}
          xlinkHref={'#e'}
          y={0.82}
        />
        <use
          transform={'matrix(150 0 0 -150 586.65 227.25)'}
          x={-1.13}
          xlinkHref={'#d'}
          y={0.82}
        />
      </g>
    </svg>
  )
}
